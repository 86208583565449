



















































































































































































































































































































































































import { generateUuid } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { QUOTATION_STATUS } from "@/models/enums/quotation.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { formatCurrency, formatterNumber, formatterPercent, reverseFormatNumber, reverseFormatPercent } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@constant/global.constant";
import { IOption } from "@interface/common.interface";
import { ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { ResponseDetailProduct, ResponseProduct } from "@interface/product.interface";
import { ResponseQuotationConsume, ResponseQuotationConsumeProduct, ResponseQuotationDetail } from "@interface/quotation.interface";
import { UomConversions } from "@interface/uom.interface";
import { fileServices } from "@service/file.service";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { quotationService } from "@service/quotation.service";
import Vue from "vue";
import { IProcessInfo } from "./components/QuotationTableProcessInfo.vue";

export interface IConsumedProduct {
  id: string;
  key: string;
  productCode: string;
  productId: string;
  productName: string;
  uomId: string;
  uom: string;
  basePrice: number;
}

export interface ITableRow {
  id: string;
  key: string;
  no: number;
  qtyOrder: number;
  sellPrice: number;
  discount: number;
  warehouseNote: string;
  condition: string;
  invoiceWeight: string;
  consumedProducts: IConsumedProduct[];
  headerColProducts: string[];
  deletedConsumedProducts: string[];
  isUnderMaximumBasePrice: boolean;
  alias: string;
  processInfo: IProcessInfo[];
  isProcessed: boolean;
}

export default Vue.extend({
  name: "QuotationTable",
  components: {
    CSelectMasterProductCode: () => import(/*webpackPrefetch: true*/"@/components/shared/select-master-product-code/CSelectMasterProductCode.vue"),
    CSelectMasterProductName: () => import(/*webpackPrefetch: true*/"@/components/shared/select-master-product-name/CSelectMasterProductName.vue"),
    CSelectUomConv: () => import(/*webpackPrefetch: true*/"@/components/shared/select-uom-conversions/CSelectUomConv.vue"),
    CSelectCondition: () => import(/*webpackPrefetch: true*/"@/components/shared/select-condition-quotation/CSelectConditionQuotation.vue"),
    CSelectInvoiceWeight: () => import(/*webpackPrefetch: true*/"@/components/shared/select-invoice-weight/CSelectInvoiceWeight.vue"),
    QuotationTableProcessInfo: () => import(/*webpackPrefetch: true*/"./components/QuotationTableProcessInfo.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  props: {
    propBranchId: {
      type: String,
      required: true,
      default: ""
    },
    propCustomerId: {
      type: String,
      required: true,
      default: ""
    },
    propDetailQuotation: {
      type: Object as () => ResponseQuotationDetail,
      required: false,
      default: null
    }
  },
  data() {
    this.emitData = debounceProcess(this.emitData, 300);
    this.validateBasePrice = debounceProcess(this.validateBasePrice, 300);
    return {
      QUOTATION_STATUS,
      DECIMAL_PLACES_QTY,
      selectedRowKeys: [] as string[],
      productLines: [] as ITableRow[],
      vmProduct: {
        uomId: "",
        uom: "",
        productName: "",
        productCode: "",
        productId: "",
        basePrice: 0,
        locationRackId: "",
        locationRack: ""
      },
      loading: {
        locationRack: false,
        productCode: false,
        productName: false,
        latestorder: false
      },
      dtOpt: {
        locationRack: [] as IOption[],
        productCode: [] as IOption[],
        productName: [] as IOption[],
        uom: [] as IOption[],
      },
      dtList: {
        productCode: {} as ResponseListInventoryLineBatch,
        productName: {} as ResponseListInventoryLineBatch,
        locationRack: {} as ResponseListInventoryLineBatch,
        productUomConversions: [] as UomConversions[]
      },
      productImage: "" as string | ArrayBuffer | null,
      deletedRow: [] as string[]
    };
  },
  computed: {
    getBaseUrl(): string {
      return fileServices.baseUrl();
    },
    isViewDetail(): boolean {
      return !!this.$route.params.id;
    },
    isProductEmpty(): boolean {
      return !this.vmProduct.productCode || !this.vmProduct.productName || !this.vmProduct.uom;
    },
    isWarehousePicked(): boolean {
      return this.propDetailQuotation.status === QUOTATION_STATUS.WAREHOUSE_PICKED;
    },
    isCancelled(): boolean {
      return this.propDetailQuotation.status === QUOTATION_STATUS.CANCELLED;
    },
    isQuotationSubmitted(): boolean {
      return this.propDetailQuotation.status === QUOTATION_STATUS.QUOTATION_SUBMITTED;
    },
    isNew(): boolean {
      return this.propDetailQuotation.status === QUOTATION_STATUS.NEW;
    },
  },
  watch: {
    propDetailQuotation: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) this.prefillTable(this.propDetailQuotation);
      }
    }
  },
  methods: {
    formatCurrency,
    formatterNumber,
    reverseFormatNumber,
    formatterPercent,
    reverseFormatPercent,
    getLatestQuotation(customerId: string): Promise<ResponseQuotationDetail> {
      return quotationService.getLatestQuotation(customerId);
    },
    getListInventoryLinesBatch(params: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(params);
    },
    onRowSelect(rowKeys: string[]): void {
      this.selectedRowKeys = rowKeys;
    },
    emitData(): void {
      this.$emit("on-data-update", { productLines: this.productLines, deletedRow: this.deletedRow });
    },
    addRow(): void {
      const { productLines } = this;
      const newRow: ITableRow = {
        id: "",
        key: generateUuid(),
        no: productLines.length + 1,
        qtyOrder: 0,
        sellPrice: 0,
        discount: 0,
        warehouseNote: "",
        condition: "",
        invoiceWeight: "",
        headerColProducts: [],
        consumedProducts: [],
        deletedConsumedProducts: [],
        isUnderMaximumBasePrice: true,
        alias: "",
        processInfo: [],
        isProcessed: false,
      };
      this.productLines = [...productLines, newRow];
    },
    resetFormConsumedProduct(): void {
      this.vmProduct = {
        uomId: "",
        uom: "",
        productName: "",
        productCode: "",
        productId: "",
        basePrice: 0,
        locationRackId: "",
        locationRack: ""
      };
    },
    findMaxBasePrice(record: ITableRow): number {
      const prices: number[] = record.consumedProducts.map(x => x.basePrice);
      const max = prices.reduce((a, b) => {
        return Math.max(a, b);
      }, 0);
      return max || 0;
    },
    validateBasePrice(record: ITableRow): void {
      if (record.sellPrice < this.findMaxBasePrice(record)) {
        record.isUnderMaximumBasePrice = true;
      } else {
        record.isUnderMaximumBasePrice = false;
      }
    },
    addConsumedProduct(record: ITableRow): void {
      const { vmProduct } = this;
      const newProd: IConsumedProduct = {
        id: "",
        key: generateUuid(),
        productCode: vmProduct.productCode,
        productId: vmProduct.productId,
        productName: vmProduct.productName,
        uom: vmProduct.uom,
        uomId: vmProduct.uomId,
        basePrice: vmProduct.basePrice,
      };
      record.consumedProducts.push(newProd);
      record.headerColProducts = [...record.consumedProducts].map(x => {return x.productName;});
      this.resetFormConsumedProduct();
      this.emitData();
    },
    removeConsumedProduct(item: IConsumedProduct, record: ITableRow): void {
      const { consumedProducts } = record;
      record.deletedConsumedProducts.push(item.id);
      record.consumedProducts = consumedProducts.filter(x => x.key !== item.key);
      this.emitData();
    },
    onselectProduct(e: {value: string, meta: IOption<ResponseProduct>}, from: "code" | "name"): void {
      if (from === "code") {
        this.vmProduct.productName = e.meta.meta?.name || "";
      } else if (from === "name") {
        this.vmProduct.productCode = e.meta.meta?.code || "";
      }
      this.vmProduct.productId = e.meta.meta?.id || "";
    },
    onchangeUom(e: ResponseDetailProduct): void {
      if (!e.uomConversions) return;
      const prod = e.uomConversions.find(x => x.unitUomId === this.vmProduct.uomId);
      this.vmProduct.basePrice = prod?.bottomPrice || 0;
      this.vmProduct.uom = prod?.unitUom || "";
      this.emitData();
    },
    /**
     * fill data from detail quotation
     */
    prefillTable(detailQuotation: ResponseQuotationDetail): void {
      try {
        const { consume } = detailQuotation;
        this.productLines = consume?.map((x: ResponseQuotationConsume, i) => {
          return {
            processInfo: x.processInfo.map((y) => ({
              ...y,
              key: generateUuid(),
            })),
            alias: x.alias,
            id: x.id,
            key: x.id,
            no: i + 1,
            qtyOrder: x.qtyOrder || 0,
            sellPrice: x.salesPrice || 0,
            discount: x.discount || 0,
            warehouseNote: x.warehouseNote || "",
            condition: x.condition || "",
            invoiceWeight: x.invoiceWeight || "",
            deletedConsumedProducts: [],
            isUnderMaximumBasePrice: this.findConditionBasePrice(x.salesPrice, x.consumeProduct),
            consumedProducts: x.consumeProduct.map((y: ResponseQuotationConsumeProduct, j) => {
              return {
                id: y.id,
                key: y.id,
                productCode: y.productCode || "",
                productId: y.productId || "",
                productName: y.productName || "",
                uomId: y.uomId || "",
                uom: y.uomName || "",
                basePrice: y.basePrice || 0,
                locationRackId: y.locationId || "",
                locationRack: y.locationName || "",
              };
            }),
            headerColProducts: x.consumeProduct.map(z => {
              return z.productName;
            }),
            isProcessed: x.isProcessed,
          };
        }) || [];
        this.productLines.forEach(x => {
          this.validateBasePrice(x);
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    findConditionBasePrice(salePrice: number, consume: ResponseQuotationConsumeProduct[]): boolean {
      const prices: number[] = consume.map(x => x.basePrice);
      const max = prices.reduce((a, b) => {
        return Math.max(a, b);
      }, 0);
      return salePrice < max;
    },
    deleteRow(): void {
      const { productLines } = this;
      this.productLines = productLines.filter(x => {
        if (this.selectedRowKeys.includes(x.key)) {
          this.deletedRow.push(x.id);
        }
        return !this.selectedRowKeys.includes(x.key);
      });
      this.productLines.forEach((x, i) => {
        x.no = i + 1;
      });
      this.selectedRowKeys = [];
      this.emitData();
    },
    async findLatestOrder(): Promise<void> {
      try {
        this.loading.latestorder = true;
        const res = await this.getLatestQuotation(this.propCustomerId);
        this.prefillTable(res);
        this.$emit("on-latest-order", { order: res });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.latestorder = false;
      }
    },
    onChangeProcessInfo(e, record): void {
      record.processInfo = e.data;
      this.emitData();
    },
  }
});
