import { Api } from "@/models/class/api.class";
import { ResponseListInventoryLineBatch } from "@/models/interface-v2/inventory-line-batch.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class InventoryLineBatchServices extends HttpClient {
  constructor() {
    super();
  }

  getListInventoryLineBatch(params: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
    return this.get<ResponseListInventoryLineBatch>(Api.InventoryLineBatch, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  print(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.InventoryLineBatch}/print`, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  download(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.InventoryLineBatch}/download`, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const inventoryLineBatchService = new InventoryLineBatchServices();
