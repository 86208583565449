var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 8] } },
    [
      _vm.isQuotationSubmitted || _vm.isNew
        ? _c(
            "a-col",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "delete", type: "danger" },
                      on: { click: _vm.deleteRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  ),
                  !_vm.isViewDetail
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "search",
                            loading: _vm.loading.latestorder,
                            disabled: !_vm.propCustomerId
                          },
                          on: { click: _vm.findLatestOrder }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_latest_order")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-col",
        [
          _c(
            "a-table",
            {
              attrs: {
                "data-source": _vm.productLines,
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onRowSelect
                },
                "default-expand-all-rows": true,
                loading: false,
                pagination: {
                  defaultPageSize: 20,
                  showTotal: function(total) {
                    return _vm.$t("lbl_total_items", { total: total })
                  }
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "expandedRowRender",
                  fn: function(record) {
                    return [
                      _vm.isNew || _vm.isQuotationSubmitted
                        ? [
                            _c("h3", [
                              _vm._v(
                                _vm._s(_vm.$t("lbl_choose_consumed_product"))
                              )
                            ]),
                            _vm.isNew || _vm.isQuotationSubmitted
                              ? _c(
                                  "a-row",
                                  { attrs: { gutter: [16, 16], type: "flex" } },
                                  [
                                    _c(
                                      "a-col",
                                      {
                                        attrs: { sm: 24, md: 12, lg: 8, xl: 6 }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_product_code")) +
                                            " "
                                        ),
                                        _c("CSelectMasterProductCode", {
                                          attrs: {
                                            "prop-search-by": "parent~true"
                                          },
                                          on: {
                                            "on-select": function(e) {
                                              return _vm.onselectProduct(
                                                e,
                                                "code"
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.vmProduct.productCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.vmProduct,
                                                "productCode",
                                                $$v
                                              )
                                            },
                                            expression: "vmProduct.productCode"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.vmProduct
                                                  .productCode,
                                                expression:
                                                  "!vmProduct.productCode"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      {
                                        attrs: { sm: 24, md: 12, lg: 8, xl: 6 }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_product_name")) +
                                            " "
                                        ),
                                        _c("CSelectMasterProductName", {
                                          attrs: {
                                            "prop-search-by": "parent~true"
                                          },
                                          on: {
                                            "on-select": function(e) {
                                              return _vm.onselectProduct(
                                                e,
                                                "name"
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.vmProduct.productName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.vmProduct,
                                                "productName",
                                                $$v
                                              )
                                            },
                                            expression: "vmProduct.productName"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.vmProduct
                                                  .productName,
                                                expression:
                                                  "!vmProduct.productName"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { sm: 24, md: 12, lg: 6 } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("lbl_uom")) + " "
                                        ),
                                        _c("CSelectUomConv", {
                                          attrs: {
                                            "prop-product-id":
                                              _vm.vmProduct.productId
                                          },
                                          on: {
                                            "on-select-with-detail": function(
                                              e
                                            ) {
                                              return _vm.onchangeUom(e)
                                            }
                                          },
                                          model: {
                                            value: _vm.vmProduct.uomId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.vmProduct,
                                                "uomId",
                                                $$v
                                              )
                                            },
                                            expression: "vmProduct.uomId"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.vmProduct.uomId,
                                                expression: "!vmProduct.uomId"
                                              }
                                            ],
                                            staticClass:
                                              "validate-error ant-form-explain"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_validation_required_error"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { sm: 24, md: 12, lg: 6 } },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_bottom_price"))
                                          )
                                        ]),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.vmProduct.basePrice
                                                    ? _vm.vmProduct.basePrice
                                                    : 0
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "a-row",
                              {
                                attrs: {
                                  gutter: [16, 16],
                                  type: "flex",
                                  align: "middle"
                                }
                              },
                              [
                                _c("a-col", { attrs: { sm: 24, md: 12 } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_highest_bottom_price")
                                      ) +
                                      ": "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-subtitle-2" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.findMaxBasePrice(record) + ""
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "a-col",
                                  { attrs: { sm: 24, md: 12, align: "end" } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          icon: "plus",
                                          type: "primary",
                                          disabled: _vm.isProductEmpty
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addConsumedProduct(
                                              record
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("lbl_add")) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("a-divider")
                          ]
                        : _vm._e(),
                      _c("a-list", {
                        attrs: {
                          grid: { gutter: 16, sm: 1, md: 2, lg: 3 },
                          "data-source": record.consumedProducts,
                          size: "small"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "renderItem",
                              fn: function(item, index) {
                                return _c(
                                  "a-list-item",
                                  {},
                                  [
                                    _c(
                                      "a-card",
                                      {
                                        attrs: {
                                          title:
                                            _vm.$t("lbl_consumed_product") +
                                            " " +
                                            (index + 1)
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "p-3" }, [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("lbl_product_code")
                                              ) +
                                                ": " +
                                                _vm._s(item.productCode)
                                            )
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("lbl_product_name")
                                              ) +
                                                ": " +
                                                _vm._s(item.productName)
                                            )
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.$t("lbl_uom")) +
                                                ": " +
                                                _vm._s(item.uom)
                                            )
                                          ]),
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.$t("lbl_base_price")) +
                                                ": " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    item.basePrice
                                                  )
                                                )
                                            )
                                          ])
                                        ]),
                                        _c(
                                          "template",
                                          {
                                            staticClass: "ant-card-actions",
                                            slot: "actions"
                                          },
                                          [
                                            _c("a-icon", {
                                              key: "edit",
                                              attrs: { type: "delete" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeConsumedProduct(
                                                    item,
                                                    record
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _c("a-divider"),
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("lbl_produced_product")))
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16], type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_invoice_description")) +
                                  " "
                              ),
                              _c("a-input", {
                                attrs: {
                                  "allow-clear": "",
                                  placeholder: _vm.$t("lbl_type_here")
                                },
                                model: {
                                  value: record.alias,
                                  callback: function($$v) {
                                    _vm.$set(record, "alias", $$v)
                                  },
                                  expression: "record.alias"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_qty_order")) + " "
                              ),
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  min: 0,
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_QTY
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.emitData()
                                  }
                                },
                                model: {
                                  value: record.qtyOrder,
                                  callback: function($$v) {
                                    _vm.$set(record, "qtyOrder", _vm._n($$v))
                                  },
                                  expression: "record.qtyOrder"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: record.qtyOrder === 0,
                                      expression: "record.qtyOrder === 0"
                                    }
                                  ],
                                  staticClass: "validate-error ant-form-explain"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_validation_required_error")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_sell_price")) + " "
                              ),
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 0,
                                  precision: _vm.DECIMAL_PLACES_QTY
                                },
                                on: {
                                  change: function($event) {
                                    _vm.validateBasePrice(record)
                                    _vm.emitData()
                                  }
                                },
                                model: {
                                  value: record.sellPrice,
                                  callback: function($$v) {
                                    _vm.$set(record, "sellPrice", _vm._n($$v))
                                  },
                                  expression: "record.sellPrice"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !record.sellPrice,
                                      expression: "!record.sellPrice"
                                    }
                                  ],
                                  staticClass: "validate-error ant-form-explain"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_validation_required_error")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        record.sellPrice &&
                                        record.sellPrice <
                                          _vm.findMaxBasePrice(record),
                                      expression:
                                        "record.sellPrice && record.sellPrice < findMaxBasePrice(record)"
                                    }
                                  ],
                                  staticClass:
                                    "validate-warning ant-form-explain"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "lbl_validation_sell_price_below_max_bottom_price"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_discount") + "(%)") +
                                  " "
                              ),
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  formatter: _vm.formatterPercent,
                                  parser: _vm.reverseFormatPercent,
                                  min: 0,
                                  max: 100,
                                  precision: _vm.DECIMAL_PLACES_QTY
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.emitData()
                                  }
                                },
                                model: {
                                  value: record.discount,
                                  callback: function($$v) {
                                    _vm.$set(record, "discount", _vm._n($$v))
                                  },
                                  expression: "record.discount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_warehouse_notes")) +
                                  " "
                              ),
                              _c("a-textarea", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t("lbl_type_here"),
                                  "auto-size": { maxRows: 4 },
                                  "allow-clear": ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.emitData()
                                  }
                                },
                                model: {
                                  value: record.warehouseNote,
                                  callback: function($$v) {
                                    _vm.$set(record, "warehouseNote", $$v)
                                  },
                                  expression: "record.warehouseNote"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_condition")) + " "
                              ),
                              _c("CSelectCondition", {
                                on: { "on-select": _vm.emitData },
                                model: {
                                  value: record.condition,
                                  callback: function($$v) {
                                    _vm.$set(record, "condition", $$v)
                                  },
                                  expression: "record.condition"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !record.condition,
                                      expression: "!record.condition"
                                    }
                                  ],
                                  staticClass: "validate-error ant-form-explain"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_validation_required_error")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { sm: 24, md: 12, lg: 6 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_invoice_weight")) + " "
                              ),
                              _c("CSelectInvoiceWeight", {
                                on: { "on-select": _vm.emitData },
                                model: {
                                  value: record.invoiceWeight,
                                  callback: function($$v) {
                                    _vm.$set(record, "invoiceWeight", $$v)
                                  },
                                  expression: "record.invoiceWeight"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !record.invoiceWeight,
                                      expression: "!record.invoiceWeight"
                                    }
                                  ],
                                  staticClass: "validate-error ant-form-explain"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_validation_required_error")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("a-col", { attrs: { sm: 24, md: 12, lg: 6 } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_process")) + " "),
                            _c(
                              "div",
                              [
                                _c("a-checkbox", {
                                  model: {
                                    value: record.isProcessed,
                                    callback: function($$v) {
                                      _vm.$set(record, "isProcessed", $$v)
                                    },
                                    expression: "record.isProcessed"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c("a-divider"),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16], type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("QuotationTableProcessInfo", {
                                attrs: {
                                  "is-processed": record.isProcessed,
                                  "process-info": record.processInfo
                                },
                                on: {
                                  "on-change": function(e) {
                                    return _vm.onChangeProcessInfo(e, record)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "a-table-column",
                { key: "no", attrs: { "data-index": "no", width: 100 } },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "alias",
                  attrs: { "data-index": "alias", ellipsis: true },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text) {
                        return [_vm._v(" " + _vm._s(text || "-") + " ")]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_invoice_description")))
                  ])
                ]
              ),
              _c(
                "a-table-column",
                {
                  key: "qtyOrder",
                  attrs: { "data-index": "qtyOrder" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(text, record) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("toDecimalQty")(record.qtyOrder))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("lbl_qty_order")))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }